import React, { useState, useEffect } from 'react';
import {Form, Row, Col, Input, Button, Select, DatePicker} from 'antd';
import SelectData from './selectdata';
import {CisUI} from "./CISUI";
import StaticSelectData from "./StaticSelectData";
import IntlMessages from "./IntlMessages";
import axios from "./Api";



const { RangePicker } = DatePicker;
const { Option } = Select;

// const dateFormat = 'YYYY/MM/DD';

const AdvancedSearchBeneficiary = (inputs,endpoint="/",hasDataRange=0,pickertype="",hasePeriod= null, form = null) => {
    // const [expand, setExpand] = useState(false);
    // const [form] = Form.useForm();
    const dateFormat = 'MM/DD/YYYY';

    let advRef =  React.createRef();


    const BenificiaryType = SelectData("accountbenificary");
    const [comID, setComID] = useState(0);
    const [vendor, setVendor] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [accounts, setAccount] = useState([]);
    const [Benificary, setBenificary] = useState([]);
    const [beneficiaryData, setBeneficiaryData] = useState({
        department: [],
        users: [],
        ministry: [],
        pentsos: [],
        area: [],
        district: [],
        local: []
    });

    useEffect(() => {
        // console.log("eff")

        // const value = CisUI().getUserInfo('com_id');

        let com_id = 0;
        if(comID === 0) {
            com_id = CisUI().getUserInfo('com_id');
        }
        else{
            com_id = comID;
        }

        // console.log("eff", com_id)
        axios.get("billentrydata?com_id=" + com_id)
            .then((res) => {
                // console.log(res.data);
                if (res.data.status === 1) {
                    setVendor(res.data.vendor);
                    setAccount(res.data.account);
                    setEmployee(res.data.employee);
                    setCustomer(res.data.customer);
                    setBeneficiaryData({
                        department: res.data.department,
                        users: res.data.users,
                        ministry: res.data.ministry,
                        pentsos: res.data.pentsos,
                        area: res.data.area,
                        district: res.data.district,
                        local: res.data.local
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
        // }
    }, [comID]);



    const onRangeChange = (value, dateString) => {
        // console.log('Selected Time: ', value);
        // console.log('Formatted Selected Time: ', dateString);
    }

    const onCompanyChange = (e) => {
        // console.log("company", e)
        setComID(e)
    }

    const onBenificaryChange = (e) => {
        //setBenificiaryselected(e.target.value);
        console.log("Selected : ", e);
        if (e === 2) {
            setBenificary(customer);
        } else if (e === 3) {
            setBenificary(vendor);
        } else if (e === 4) {
            setBenificary(employee);
        } else if (e === 5) {
            setBenificary(accounts);
        } else if (e === 6) {
            setBenificary(beneficiaryData.department);
        } else if (e === 7) {
            setBenificary(beneficiaryData.area);
        } else if (e === 8) {
            setBenificary(employee);
        } else if (e === 9) {
            setBenificary(beneficiaryData.users);
        } else if (e === 10) {
            // console.log(e);
            // console.log(beneficiaryData.ministry);
            setBenificary(beneficiaryData.ministry);
        } else if (e === 11) {
            setBenificary(beneficiaryData.pentsos);
        } else if (e === 12) {
            setBenificary(beneficiaryData.district);
        } else if (e === 13) {
            setBenificary(beneficiaryData.local);
        }

        // if(comID === 0){
        //     const value = CisUI().getUserInfo('com_id');
        //     setComID(value)
        // }

        // form.setFieldsValue({
        //     beneficiary_id: null
        // });

    }


    let hasSearch = 0
    const getFields = () => {
        const count = inputs.length;
        const children = [];
        //let hasSearch = 0;
        //const allinputs = inputs;
        inputs.map((list, index) => {
            if (list.isFilterable == 1) {
                hasSearch = 1;
                let field = "";
                if (list.type == 'text' || list.type == 'phone' || list.type == 'email') {
                    field = <Form.Item
                        name={`${list.name}`}
                        label={<IntlMessages id={`${list.label}`} />}
                        rules={[
                            {
                                required: false,
                                message: list.placeholder
                            },
                        ]}
                    >
                        <Input placeholder={list.placeholder}/>
                    </Form.Item>
                } else if(list.type == 'date'){
                    field = <Form.Item
                        name={`${list.name}`}
                        label={<IntlMessages id={`${list.label}`} />}
                        rules={[
                            {
                                required: false,
                                message: list.placeholder
                            },
                        ]}
                    >
                        <DatePicker className="gx-mb-3 gx-w-100" placeholder={list.placeholder}/>
                    </Form.Item>
                } else if (list.type === 'staticselect') {
                    // const dataOptions = SelectData(list.data);
                    const dataOptions1 = StaticSelectData(list.name);
                    // console.log("CompanyList : "+dataOptions);

                        field = <Form.Item
                            name={`${list.name}`}
                            label={<IntlMessages id={`${list.label}`} />}
                            rules={[
                                {
                                    required: false,
                                    message: list.placeholder
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear={true}
                                placeholder={list.placeholder}
                                optionFilterProp="children"
                                onChange={onCompanyChange}
                                filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                            >
                                {
                                    list.isStatic === 1 && (
                                        dataOptions1.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    )
                                }
                            </Select>
                        </Form.Item>

                    } else if (list.type == 'select') {

                    if(list.name === 'company_id' || list.name === 'com_id') {
                        const companyID = CisUI().getUserInfo('com_id');
                        if(companyID > 0) {

                        }
                        else {
                            const dataOptions = SelectData(list.data);
                            const dataOptions1 = StaticSelectData(list.name);
                            // console.log("CompanyList : "+dataOptions);

                            field = <Form.Item
                                name={`${list.name}`}
                                label={<IntlMessages id={`${list.label}`} />}
                                rules={[
                                    {
                                        required: false,
                                        message: list.placeholder
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear={true}
                                    placeholder={list.placeholder}
                                    optionFilterProp="children"
                                    onChange={onCompanyChange}
                                    filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                >
                                    {
                                        list.isStatic === 1 && (
                                            dataOptions1.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        )
                                    }
                                    {
                                        dataOptions.map((items) =>
                                            <Option value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        }
                    }
                    else if(list.name === 'local_id') {
                        const userArea = CisUI().getUserInfo('comarea');
                        if(userArea >= 6) {

                        }
                        else {
                            const dataOptions = SelectData(list.data);
                            const dataOptions1 = StaticSelectData(list.name);
                            // console.log("CompanyList : "+dataOptions);

                            field = <Form.Item
                                name={`${list.name}`}
                                label={<IntlMessages id={`${list.label}`} />}
                                rules={[
                                    {
                                        required: false,
                                        message: list.placeholder
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear={true}
                                    placeholder={list.placeholder}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                >
                                    {
                                        list.isStatic === 1 && (
                                            dataOptions1.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        )
                                    }
                                    {
                                        dataOptions.map((items) =>
                                            <Option value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        }
                    }
                    else if(list.name === 'district_id') {
                        const userArea = CisUI().getUserInfo('comarea');
                        if(userArea >= 5) {

                        }
                        else {
                            const dataOptions = SelectData(list.data);
                            const dataOptions1 = StaticSelectData(list.name);
                            field = <Form.Item
                                name={`${list.name}`}
                                label={<IntlMessages id={`${list.label}`} />}
                                rules={[
                                    {
                                        required: false,
                                        message: list.placeholder
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear={true}
                                    placeholder={list.placeholder}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                >
                                    {
                                        list.isStatic === 1 && (
                                            dataOptions1.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        )
                                    }
                                    {
                                        dataOptions.map((items) =>
                                            <Option value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        }
                    }
                    else if(list.name === 'region_id') {
                        const userArea = CisUI().getUserInfo('comarea');
                        if(userArea >= 4) {

                        }
                        else {
                            const dataOptions = SelectData(list.data);
                            const dataOptions1 = StaticSelectData(list.name);
                            field = <Form.Item
                                name={`${list.name}`}
                                label={<IntlMessages id={`${list.label}`} />}
                                rules={[
                                    {
                                        required: false,
                                        message: list.placeholder
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear={true}
                                    placeholder={list.placeholder}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                >
                                    {
                                        list.isStatic === 1 && (
                                            dataOptions1.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        )
                                    }
                                    {
                                        dataOptions.map((items) =>
                                            <Option value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        }
                    }
                    else if(list.name === 'beneficiary_type') {
                        field = <Form.Item
                            name={`${list.name}`}
                            label={<IntlMessages id={`${list.label}`} />}
                            rules={[
                                {
                                    required: false,
                                    message: list.placeholder
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear={true}
                                placeholder={list.placeholder}
                                optionFilterProp="children"
                                onChange={onBenificaryChange}
                                filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                            >
                                {
                                    BenificiaryType.map((items) =>
                                        <Option value={items.id}>{items.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    }
                    else if(list.name === 'beneficiary_id') {
                        field = <Form.Item
                            name={`${list.name}`}
                            label={<IntlMessages id={`${list.label}`} />}
                            rules={[
                                {
                                    required: false,
                                    message: list.placeholder
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear={true}
                                placeholder={list.placeholder}
                                mode={list?.isMultiple === 1 ? "multiple" : "single"}
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                            >
                                {
                                    Benificary.map((items) =>
                                        <Option value={items.id}>{items.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    }
                    else {
                        let dataOptions1 = [];
                        let dataOptions = [];

                        if(list.isStatic === 1) {
                            dataOptions1 = StaticSelectData(list.name);
                        }
                        else {
                            dataOptions = SelectData(list.data);
                        }
                        //  console.log("ListData : "+dataOptions1);
                        // console.log(list.data);

                        field = <Form.Item
                            name={`${list.name}`}
                            label={<IntlMessages id={`${list.label}`} />}
                            rules={[
                                {
                                    required: false,
                                    message: list.placeholder
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear={true}
                                mode={list?.isMultiple === 1 ? "multiple" : "single"}
                                placeholder={list.placeholder}
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                            >
                                {
                                    list.isStatic === 1 && (
                                        dataOptions1.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    )
                                }
                                {
                                    dataOptions.map((items) =>
                                        <Option value={items.id}>{items.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    }
                }
                if(field != '') {
                    children.push(
                        <Col lg={6} md={6} sm={12} xs={24} key={index}
                             style={{display: index < count ? 'block' : 'none'}}>
                            <div className="gx-form-row0">
                                {field}
                            </div>
                        </Col>,
                    );
                }
            }
        });

        if(hasDataRange === 1) {
            hasSearch = 1;

            if(hasePeriod !== null) {
                children.push(hasePeriod);
            }

            children.push(
                <Col lg={6} md={6} sm={12} xs={24} >
                    <div className="gx-form-row0">

                        <Form.Item
                            ref={advRef}
                            name="date"
                            label={<IntlMessages id={"Date"} />}
                            rules={[
                                {
                                    required: false,
                                    message: "Start Date"
                                },
                            ]}
                        >

                            <RangePicker
                                onChnage={onRangeChange} format={CisUI().dateFormat} />

                            {/*<DatePicker format={CisUI().dateFormat} placeholder="Start Date"/>*/}
                        </Form.Item>

                    </div>
                </Col>,
            );
        }

        if(pickertype === "week") {
            hasSearch = 1;
            children.push(
                <Col lg={6} md={6} sm={12} xs={24} >
                    <div className="gx-form-row0">

                        <Form.Item
                            name={pickertype}
                            label={<IntlMessages id={"Week"} />}
                            rules={[
                                {
                                    required: false,
                                    message: {pickertype}
                                },
                            ]}
                        >

                            <DatePicker picker={pickertype} onChnage={onRangeChange} />
                        </Form.Item>

                    </div>
                </Col>,
            );
        }

        if(pickertype === "month") {
            hasSearch = 1;
            children.push(
                <Col lg={6} md={6} sm={12} xs={24} >
                    <div className="gx-form-row0">

                        <Form.Item
                            name={pickertype}
                            label={<IntlMessages id={"Month"} />}
                            rules={[
                                {
                                    required: false,
                                    message: {pickertype}
                                },
                            ]}
                        >

                            <DatePicker picker={pickertype} onChnage={onRangeChange} />
                        </Form.Item>

                    </div>
                </Col>,
            );
        }

        // if(hasSearch === 1) {
        //     children.push(
        //         <Col lg={6} md={6} sm={12} xs={24} style={{textAlign: 'left'}}>
        //             <br/>
        //             <Button type="primary" className="searchbutton" htmlType="submit">
        //                 <i class="fas fa-search" /> <IntlMessages id={"Search"} />
        //             </Button>
        //         </Col>
        //     )
        // }

        return children;
    };


    return (
        <Row gutter={24} className="print-hidden">
            <div className="col-md-11">
                <div className="row">
                    {getFields()}
                </div>
            </div>

            <div className="col-md-1">
                {
                    hasSearch === 1 ?
                        <>
                            <Col lg={6} md={6} sm={12} xs={24} style={{textAlign: 'left'}}>
                                <br/>
                                <Button type="primary" className="searchbutton" htmlType="submit">
                                    <i className="fas fa-search" /> <IntlMessages id={"Search"} />
                                </Button>
                            </Col>
                        </> : ""
                }
            </div>

        </Row>
    );
};

export default AdvancedSearchBeneficiary;
