import {Form, Row, Col, Input, Button, Card, Collapse, Select, DatePicker, Checkbox, notification, Spin} from 'antd';
import React, {useState, useEffect} from "react";
import jsonfile from './journal.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";
import moment from "moment";
import StaticSelectData from "../../../../util/StaticSelectData";

const {Option} = Select;

const JournalEdit = (props) => {

        const formRef = React.createRef();

        const [loading, setLoading] = useState(false);
        const [isProcessed, setProcessed] = useState(false);

        const [state, setState] = useState({
            data: [],
            rows: []
        })

        const [entry, setEntryDetails] = useState({
            date: null,
            com_id: 0,
            thread: ""
        });

        const [linefound, setLinefound] = useState(0);
        const [vendor, setVendor] = useState([]);

        const [customer, setCustomer] = useState([]);
        const [employee, setEmployee] = useState([]);
        const [accounts, setAccount] = useState([]);
        const [fund, setFund] = useState([]);
        const [expense, setExpense] = useState([]);
        const [bankaccounts, setBankAccounts] = useState([]);
        const [cashaccount, setCashAccount] = useState([]);
        const [mainaccount, setMainAccount] = useState([]);
        const [accountchild, setChildAccount] = useState([]);

        const [payable, setPayable] = useState([]);
        const [project, setProject] = useState([]);
        const [allbankaccounts, setAllBankAccounts] = useState([]);
        const [isLoaded, setIsLoaded] = useState(0);
        const [doc, setDoc] = useState({});
        const [Area, setArea] = useState([]);
        const [District, setDistrict] = useState([]);
        const [Local, setLocal] = useState([]);
        const [selectedSegment, setSelectedSegment] = useState([]);

        const [Title, setTitle] = useState(jsonfile.edittitle);
        const [stateD, setStateD] = useState([]);

        const [benificiariesData, setBenificiaryData] = useState([]);

        const [ttl, setTTL] = useState(
            {
                totalDebit: 0,
                totalCredit: 0
            }
        );

        const [beneficiaryData, setBeneficiaryData] = useState({
            department: [],
            users: [],
            ministry: [],
            pentsos: [],
            subsector: [],
            committee: [],
            area: [],
            district: [],
            local: []
        });

        // const Area = SelectData("allarea");
        // const District = SelectData("alldistrict");
        // const Local = SelectData("alllocal");

        const userID = props.match.params.id;
        let userData = [];
        // console.log("Userid : "+props.match.params.id);
        let isEdit = 0;
        if (userID > 0) {
            isEdit = 1;
        }

        let endpoint = jsonfile.urls.edit;
        let redirectto = "../" + jsonfile.urls.list;

        if (isEdit === 1) {
            //userData = RemoteJson();
            endpoint = jsonfile.urls.edit + "/" + userID;
            redirectto = "../../" + jsonfile.urls.list;
        }


        const [form] = Form.useForm();
        const history = useHistory();

        const Benificiary = SelectData("accountbenificary");
        let BenificaryData = [];

        const ItemCellChange = (idx, cname = "") => e => {
            let name = "";
            let value = null;
            if (cname === "") {
                name = e.target.name;
                value = e.target.value;
            } else {
                name = cname;
                value = e;
            }

            const rows = [...state.rows];
            // console.log("selectedValued:" + e);
            console.log("selectedValue:" + value);

            if (name === "account_id") {
                rows[idx] = {
                    account_id: value,
                    item_id: rows[idx].item_id,
                    description: rows[idx].description,
                    bank_account_id: rows[idx].bank_account_id,
                    fund_program: rows[idx].fund_program || 0,
                    func_expense_id: rows[idx].func_expense_id || 0,
                    restriction_type: rows[idx].restriction_type || "",
                    beneficiary_source: rows[idx].beneficiary_source,
                    beneficiary_id: rows[idx].beneficiary_id,
                    debit: rows[idx].debit,
                    credit: rows[idx].credit,
                    beneficiary : rows[idx].beneficiary,
                };
            } else if (name === "description") {
                rows[idx] = {
                    item_id: rows[idx].item_id,
                    account_id: rows[idx].account_id,
                    description: value,
                    bank_account_id: rows[idx].bank_account_id,
                    fund_program: rows[idx].fund_program || 0,
                    func_expense_id: rows[idx].func_expense_id || 0,
                    restriction_type: rows[idx].restriction_type || "",
                    beneficiary_source: rows[idx].beneficiary_source,
                    beneficiary_id: rows[idx].beneficiary_id,
                    debit: rows[idx].debit,
                    credit: rows[idx].credit,
                    beneficiary : rows[idx].beneficiary,
                };
            } else if (name === "fund_program") {
                rows[idx] = {
                    item_id: rows[idx].item_id,
                    account_id: rows[idx].account_id,
                    description: rows[idx].description,
                    bank_account_id: rows[idx].bank_account_id,
                    fund_program: value || 0,
                    func_expense_id: rows[idx].func_expense_id || 0,
                    restriction_type: rows[idx].restriction_type || "",
                    beneficiary_source: rows[idx].beneficiary_source,
                    beneficiary_id: rows[idx].beneficiary_id,
                    debit: rows[idx].debit,
                    credit: rows[idx].credit,
                    beneficiary : rows[idx].beneficiary,
                };
            } else if (name === "func_expense_id") {
                rows[idx] = {
                    item_id: rows[idx].item_id,
                    account_id: rows[idx].account_id,
                    description: rows[idx].description,
                    bank_account_id: rows[idx].bank_account_id,
                    fund_program: rows[idx].fund_program || 0,
                    restriction_type: rows[idx].restriction_type || "",
                    beneficiary_source: rows[idx].beneficiary_source,
                    beneficiary_id: rows[idx].beneficiary_id,
                    func_expense_id: value || 0,
                    debit: rows[idx].debit,
                    credit: rows[idx].credit,
                    beneficiary : rows[idx].beneficiary,
                };
            } else if (name === "debit") {
                rows[idx] = {
                    item_id: rows[idx].item_id,
                    account_id: rows[idx].account_id,
                    description: rows[idx].description,
                    bank_account_id: rows[idx].bank_account_id,
                    fund_program: rows[idx].fund_program || 0,
                    func_expense_id: rows[idx].func_expense_id || 0,
                    restriction_type: rows[idx].restriction_type || "",
                    beneficiary_source: rows[idx].beneficiary_source,
                    beneficiary_id: rows[idx].beneficiary_id,
                    debit: value,
                    credit: rows[idx].credit,
                    beneficiary : rows[idx].beneficiary,
                };
            } else if (name === "credit") {
                rows[idx] = {
                    item_id: rows[idx].item_id,
                    account_id: rows[idx].account_id,
                    description: rows[idx].description,
                    bank_account_id: rows[idx].bank_account_id,
                    fund_program: rows[idx].fund_program || 0,
                    func_expense_id: rows[idx].func_expense_id || 0,
                    restriction_type: rows[idx].restriction_type || "",
                    beneficiary_source: rows[idx].beneficiary_source,
                    beneficiary_id: rows[idx].beneficiary_id,
                    debit: rows[idx].debit,
                    credit: value,
                    beneficiary : rows[idx].beneficiary,
                };
            } else if (name === "bank_account_id") {
                rows[idx] = {
                    item_id: rows[idx].item_id,
                    account_id: rows[idx].account_id,
                    description: rows[idx].description,
                    bank_account_id: value,
                    fund_program: rows[idx].fund_program || 0,
                    func_expense_id: rows[idx].func_expense_id || 0,
                    restriction_type: rows[idx].restriction_type || "",
                    beneficiary_source: rows[idx].beneficiary_source,
                    beneficiary_id: rows[idx].beneficiary_id,
                    debit: rows[idx].debit,
                    credit: rows[idx].credit,
                    beneficiary : rows[idx].beneficiary,
                };
            } else if (name === "restriction_type") {
                rows[idx] = {
                    item_id: rows[idx].item_id,
                    account_id: rows[idx].account_id,
                    description: rows[idx].description,
                    bank_account_id: rows[idx].bank_account_id,
                    fund_program: rows[idx].fund_program || 0,
                    func_expense_id: rows[idx].func_expense_id || 0,
                    beneficiary_source: rows[idx].beneficiary_source,
                    beneficiary_id: rows[idx].beneficiary_id,
                    restriction_type: value || "",
                    debit: rows[idx].debit,
                    credit: rows[idx].credit,
                    beneficiary : rows[idx].beneficiary,
                };
            } else if (name === "beneficiary_source") {
                // onBenificaryChange(value);

                let e = value;
                let BF = [];

                if (e === 2) {
                    BF = customer;
                } else if (e === 3) {
                    BF = vendor;
                } else if (e === 4) {
                    BF = employee;
                } else if (e === 5) {
                    BF = accounts;
                } else if (e === 6) {
                    BF = beneficiaryData.department;
                } else if (e === 7) {
                    BF = Area;
                } else if (e === 8) {
                    BF = employee;
                } else if (e === 9) {
                    BF = beneficiaryData.users;
                } else if (e === 10) {
                    BF = beneficiaryData.ministry;
                } else if (e === 11) {
                    BF = beneficiaryData.pentsos;
                } else if (e === 12) {
                    BF = District;
                } else if (e === 13) {
                    BF = Local;
                }
                else if (e === 14) {
                    BF = beneficiaryData.committee
                }
                else if (e === 15) {
                    BF = beneficiaryData.subsector;
                }

                rows[idx] = {
                    item_id: rows[idx].item_id,
                    account_id: rows[idx].account_id,
                    description: rows[idx].description,
                    bank_account_id: rows[idx].bank_account_id,
                    fund_program: rows[idx].fund_program,
                    func_expense_id: rows[idx].func_expense_id,
                    beneficiary_source: value,
                    beneficiary_id: null,
                    restriction_type: rows[idx].restriction_type,
                    debit: rows[idx].debit,
                    credit: rows[idx].credit,
                    beneficiary : BF,
                };
            } else if (name === "beneficiary_id") {
                rows[idx] = {
                    item_id: rows[idx].item_id,
                    account_id: rows[idx].account_id,
                    description: rows[idx].description,
                    bank_account_id: rows[idx].bank_account_id,
                    fund_program: rows[idx].fund_program,
                    func_expense_id: rows[idx].func_expense_id,
                    beneficiary_source: rows[idx].beneficiary_source,
                    beneficiary_id: value,
                    restriction_type: rows[idx].restriction_type,
                    debit: rows[idx].debit,
                    credit: rows[idx].credit,
                    beneficiary : rows[idx].beneficiary,
                };
            }

            setState({
                rows
            });

            //console.log(rows);

            let tDebit = 0;
            let tCredit = 0;
            rows.map((item, idx) => {
                tDebit += parseFloat(item.debit);
                tCredit += parseFloat(item.credit);
            });

            setTTL({
                totalDebit: tDebit,
                totalCredit: tCredit
            });

        };

        const handleAddRow = () => {
            const item = {
                item_id: 0,
                account_id: 0,
                description: '',
                bank_account_id: 0,
                fund_program: 0,
                func_expense_id: 0,
                beneficiary_source: 0,
                beneficiary_id: 0,
                restriction_type: null,
                debit: 0,
                credit: 0,
                beneficiary: []
            };
            // setState({
            //     rows: [...state.rows, item]
            // });
            // let rows = state.rows.concat(item);
            console.log("arows", item)
            setState({
                rows: [...state.rows, item]
            })
            console.log("rows", state.rows)
        };


        const handleRemoveRow = idx => e => {
            let filteredArray = state.rows.filter((item, index) => index !== idx)
            setState({rows: filteredArray});

            let tDebit = 0;
            let tCredit = 0;
            filteredArray.map((item, idx) => {
                tDebit += parseFloat(item.debit);
                tCredit += parseFloat(item.credit);
            });

            setTTL({
                totalDebit: tDebit,
                totalCredit: tCredit
            });

        };

        const onFinish = (values) => {
            setLoading(true);
            // console.log(JSON.stringify(values));
            let act = 0;
            let rows = [];
            state.rows.map((items, ind) => {
                if (items.account_id > 0) {
                    act += 1;
                }
                items["beneficiary"] = [];
                rows.push(items);
            });

            values["items"] = rows;

            if (act === state.rows.length) {

                if (ttl.totalDebit === ttl.totalCredit && state.rows.length > 1) {

                    if (ttl.totalDebit > 0 && ttl.totalCredit > 0) {
                        axios.post(Config.apiserver + endpoint+"/"+userID, values)
                            .then(res => {
                                console.log(res)
                                if (res.data.status === 1) {
                                    notification.success({
                                        message: 'Success',
                                        type: "success",
                                        description: res.data.msg
                                    });
                                    history.push("../../entries/journaldetails/"+userID);
                                } else {
                                    notification.warning({
                                        message: 'Alert',
                                        type: "warning",
                                        description: res.data.msg
                                    });
                                }
                                setLoading(false);
                            })
                            .catch(errors => {
                                // console.log(errors.response.data.errors);
                                setLoading(false);
                            });
                    } else {
                        setLoading(false);
                        notification.warning({
                            message: 'Alert',
                            type: "warning",
                            description: "Minimum item should be 2, total debit side and total credit side should be same"
                        });
                    }
                } else {
                    setLoading(false);
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: "Minimum item should be 2, total debit side and total credit side should be same"
                    });
                }
            } else {
                setLoading(false);
                notification.warning({
                    message: 'Alert',
                    type: "warning",
                    description: "All account field should be selected"
                });
            }

        };

        useEffect(() => {
            getReconcileData();
            // onCompanyChangeForComapny(CisUI().getUserInfo('com_id'));
        }, []);

        const getReconcileData = () => {
            //document.getElementById("loader").style.display = "block";
            const jID = props.match.params.id;
            axios.get(Config.apiserver + jsonfile.urls.viewedit + "/" + jID)
                .then(res => {
                    // console.log(res)
                    if (res.data.status === 1) {
                        const jdata = res.data.data
                        setStateD(jdata);
                        if (jdata.length > 0) {
                            // console.log(jdata[0].date);
                            setTitle(jsonfile.edittitle + " : " + jdata[0].thread);
                            setEntryDetails({
                                date: jdata[0].date,
                                com_id: jdata[0].com_id,
                                thread: jdata[0].thread
                            });

                            setLinefound(jdata.length);
                        }

                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setExpense(res.data.expense);
                        setBankAccounts(res.data.bank);
                        setAllBankAccounts(res.data.bank);
                        setEmployee(res.data.employee);
                        setCustomer(res.data.customer);
                        setProject(res.data.project);
                        setCashAccount(res.data.cashaccount);
                        setMainAccount(res.data.main_account);
                        setChildAccount(res.data.child_account);
                        setArea(res.data.area);
                        setDistrict(res.data.district);
                        setLocal(res.data.local);
                        setIsLoaded(1);
                        setBeneficiaryData({
                            department: res.data.department,
                            users: res.data.users,
                            ministry: res.data.ministry,
                            pentsos: res.data.pentsos,
                            subsector: res.data.subsector,
                            committee: res.data.committee,
                            // area: res.data.area,
                            // district: res.data.district,
                            // local: res.data.local
                        });

                        form.setFieldsValue({
                            date: moment(jdata[0].date)
                        });

                        //onCompanyChangeForComapny(jdata[0].com_id);

                        let item = [];
                        let ttld = 0.00;
                        let ttlc = 0.00;

                        jdata.map((itm, indx) => {

                            let e = itm.beneficiary_source;
                            let BF = [];

                            if (e === 2) {
                                BF = res.data.customer;
                            } else if (e === 3) {
                                BF = res.data.vendor;
                            } else if (e === 4) {
                                BF = res.data.employee;
                            } else if (e === 5) {
                                BF = res.data.accounts;
                            } else if (e === 6) {
                                BF = res.data.department;
                            } else if (e === 7) {
                                BF = res.data.area;
                            } else if (e === 8) {
                                BF = res.data.employee;
                            } else if (e === 9) {
                                BF = res.data.users;
                            } else if (e === 10) {
                                BF = res.data.ministry;
                            } else if (e === 11) {
                                BF = res.data.pentsos;
                            } else if (e === 12) {
                                BF = res.data.district;
                            } else if (e === 13) {
                                BF = res.data.local;
                            }
                            else if (e === 14) {
                                BF = res.data.committee;
                            }
                            else if (e === 15) {
                                BF = res.data.subsector;
                            }

                            // console.log("Beneficiary : " + BF);

                            item.push({
                                item_id: itm.id,
                                account_id: itm.account_id,
                                description: itm.description,
                                bank_account_id: itm.bank_account_id,
                                fund_program: itm.fund_program_id,
                                beneficiary_source: itm.beneficiary_source,
                                beneficiary_id: itm.beneficiary_id,
                                func_expense_id: itm.func_expense_id,
                                restriction_type: itm.restriction_type,
                                debit: parseFloat(itm.debit).toFixed(2),
                                credit: parseFloat(itm.credit).toFixed(2),
                                beneficiary: BF
                            });

                            // ttld += parseFloat(itm.debit || 0).toFixed(2);
                            //ttlc += parseFloat(itm.credit || 0).toFixed(2);

                        });

                        // setTTL({
                        //    totalDebit : parseFloat(ttld).toFixed(2),
                        //    totalCredit : parseFloat(ttlc).toFixed(2),
                        // });

                        setState({
                            rows: item
                        });


                        let tDebit = 0;
                        let tCredit = 0;
                        item.map((itm, idx) => {
                            tDebit += parseFloat(itm.debit);
                            tCredit += parseFloat(itm.credit);
                        });

                        setTTL({
                            totalDebit: parseFloat(tDebit).toFixed(2),
                            totalCredit: parseFloat(tCredit).toFixed(2)
                        });

                    } else {
                        notification.warning({
                            message: 'Alert',
                            type: "warning",
                            description: res.data.msg
                        });
                    }
                    // document.getElementById("loader").style.display = "none";
                })
                .catch(errors => {
                    // console.log(errors.response.data.errors);
                    //document.getElementById("loader").style.display = "none";
                });
        }

        // const onCompanyChangeForComapny = value => {
        //     console.log("selected : " + value);
        //     if (value > 0) {
        //         const company = value;
        //         axios.get("billentrydata?com_id=" + value)
        //             .then((res) => {
        //                 console.log(res.data);
        //                 if (res.data.status === 1) {
        //                     setVendor(res.data.vendor);
        //                     setAccount(res.data.account);
        //                     setPayable(res.data.payable);
        //                     setFund(res.data.fund);
        //                     setExpense(res.data.expense);
        //                     setBankAccounts(res.data.bank);
        //                     setAllBankAccounts(res.data.bank);
        //                     setEmployee(res.data.employee);
        //                     setCustomer(res.data.customer);
        //                     setProject(res.data.project);
        //                     setCashAccount(res.data.cashaccount);
        //                     setDoc(res.data.doc_class);
        //                     setSelectedSegment(res.data.doc_class[1]);
        //                     setMainAccount(res.data.main_account);
        //                     setChildAccount(res.data.child_account);
        //                     setIsLoaded(1);
        //                     setBeneficiaryData({
        //                         department: res.data.department,
        //                         users: res.data.users,
        //                         ministry: res.data.ministry,
        //                         pentsos: res.data.pentsos,
        //                         subsector: res.data.subsector,
        //                         // area: res.data.area,
        //                         // district: res.data.district,
        //                         // local: res.data.local
        //                     });
        //
        //                     getReconcileData();
        //                 }
        //             })
        //             .catch((error) => {
        //                 console.log(error);
        //             });
        //     }
        // }

        const appendChild = () => {
            let {data} = state;
            data.push(data.length); // data.length is one more than actual length since array starts from 0.
            setState({
                data: data,
                rows: {
                    code: '',
                    name: '',
                    qty: 0,
                    price: 0,
                    amount: 0
                }
            });

        };


        const removeRow = () => {
            let {data} = state;
            data.pop(data.length); // data.length is one more than actual length since array starts from 0.
            setState({data});
        }

        const onTabPress = (e) => {
            if (e.keyCode === 9) {
                e.preventDefault();
                alert("Execute ajax call after tab pressed");
            }
        }

        const onDateChange = (e) => {
            const nDate = CisUI().getSystemDateFormate(e);
            console.log(nDate);
            // setEntryDetails({
            //     date: nDate,
            //     com_id: jdata[0].com_id,
            //     thread: jdata[0].thread
            // });
        }

        const ItemCellChangeAccount = idx => e => {
            console.log("selectedValued:" + e);
            console.log("selectedValuedID:" + idx);

            const rows = [...state.rows];
            rows[idx] = {
                account_id: e,
                item_id: rows[idx].item_id,
                description: rows[idx].description,
                bank_account_id: rows[idx].bank_account_id,
                beneficiary_id: rows[idx].beneficiary_id,
                beneficiary_source: rows[idx].beneficiary_source,
                restriction_type: rows[idx].restriction_type,
                debit: rows[idx].debit,
                credit: rows[idx].credit,
                beneficiary: rows[idx].beneficiary
            };

            setState({
                rows
            });

        }

        const onBenificaryChange = (e) => {
            //setBenificiaryselected(e.target.value);
            console.log("Selected : ", e);
            if (e === 2) {
                BenificaryData = customer;
                setBenificiaryData(customer);
            } else if (e === 3) {
                BenificaryData = vendor;
                setBenificiaryData(vendor);
            } else if (e === 4) {
                BenificaryData = employee;
                setBenificiaryData(employee);
            } else if (e === 5) {
                BenificaryData = accounts;
                setBenificiaryData(accounts);
            } else if (e === 6) {
                BenificaryData = beneficiaryData.department;
                setBenificiaryData(beneficiaryData.department);
            } else if (e === 7) {
                BenificaryData = Area;
                setBenificiaryData(Area);
            } else if (e === 8) {
                BenificaryData = employee;
                setBenificiaryData(employee);
            } else if (e === 9) {
                BenificaryData = beneficiaryData.users;
                setBenificiaryData(beneficiaryData.users);
            } else if (e === 10) {
                // console.log(e);
                // console.log(beneficiaryData.ministry);
                BenificaryData = beneficiaryData.ministry;
                setBenificiaryData(beneficiaryData.ministry);
            } else if (e === 11) {
                BenificaryData = beneficiaryData.pentsos;
                setBenificiaryData(beneficiaryData.pentsos);
            } else if (e === 12) {
                BenificaryData = District;
                setBenificiaryData(District);
            } else if (e === 13) {
                BenificaryData = Local;
                setBenificiaryData(Local);
            } else if (e === 14) {
                BenificaryData = beneficiaryData.committee;
                setBenificiaryData(beneficiaryData.committee);
            } else if (e === 15) {
                BenificaryData = beneficiaryData.subsector;
                setBenificiaryData(beneficiaryData.subsector);
            }

            form.setFieldsValue({
                benificiary_id: null
            });

        }


        const formField = (

            <>
                <div className="row">
                    <div className="col-md-4">
                        <Form.Item
                            name="date"
                            label="Date"
                            initialValue={moment(entry.date)}
                            rules={[
                                {
                                    required: true,
                                    message: "date"
                                },
                            ]}
                        >
                            <DatePicker onChange={onDateChange} format={CisUI().dateFormat} placeholder="Date"/>
                        </Form.Item>
                    </div>

                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12" style={{textAlign: "right", display : "none"}}>
                                <a className="btn btn-primary" onClick={handleAddRow}>Add Lines</a>
                            </div>
                        </div>
                        <table id="itemtable" className="ptable table table-bordered">
                            <thead>
                            <tr>
                                <th></th>
                                <th>Account</th>
                                <th>Item Description</th>
                                <th>Beneficiary</th>
                                <th>Debit ({CisUI().getCurrencySymbol()})</th>
                                <th>Credit ({CisUI().getCurrencySymbol()})</th>
                            </tr>
                            </thead>
                            <tbody>
                            {state.rows.map((item, idx) => (
                                <tr id="addr0" key={idx}>
                                    <td style={{width: '30px'}}>
                                        {
                                            item.item_id === 0 ? <a onClick={handleRemoveRow(idx)}
                                                                  className="btn btn-primary btn-circle"><i
                                                className="fa fa-trash"></i></a> : <></>
                                        }
                                    </td>
                                    <td>
                                        <Select
                                            showSearch
                                            clearIcon={true}
                                            placeholder="Select an account"
                                            optionFilterProp="children"
                                            style={{width: '100%'}}
                                            value={item.account_id}
                                            onChange={ItemCellChangeAccount(idx)}
                                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                        >
                                            {
                                                accounts.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </td>
                                    <td style={{width: '300px'}}><input className="form-control text-right"
                                                                        style={{textAlign: 'left'}}
                                                                        placeholder="Description" name="description"
                                                                        value={item.description}
                                                                        onChange={ItemCellChange(idx)}/></td>

                                    <td>
                                        <Select
                                            showSearch
                                            placeholder="Select a beneficiary source"
                                            optionFilterProp="children"
                                            style={{width: '100%'}}
                                            value={item.beneficiary_source !== 0 ? item.beneficiary_source : null}
                                            onChange={ItemCellChange(idx, "beneficiary_source")}
                                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                        >
                                            {
                                                Benificiary.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                        <br/> <br/>
                                        <Select
                                            showSearch
                                            placeholder="Select a beneficiary"
                                            optionFilterProp="children"
                                            style={{width: '100%'}}
                                            value={item.beneficiary_id !== 0 ? item.beneficiary_id : null}
                                            onChange={ItemCellChange(idx, "beneficiary_id")}
                                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                        >
                                            {
                                                item.beneficiary.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </td>

                                    <td style={{width: '100px', display: 'none'}}>
                                        <select name="fund_program" onChange={ItemCellChange(idx)} className="form-control">
                                            <option value="0">--Select--</option>
                                            {
                                                fund.map((items, index) =>
                                                    <option value={items.id}>{items.name}</option>
                                                )
                                            }
                                        </select>
                                    </td>
                                    <td style={{width: '100px', display: 'none'}}>
                                        <select name="func_expense_id" onChange={ItemCellChange(idx)}
                                                className="form-control">
                                            <option value="0">--Select--</option>
                                            {
                                                expense.map((items, index) =>
                                                    <option value={items.id}>{items.name}</option>
                                                )
                                            }
                                        </select>
                                    </td>
                                    <td style={{width: '100px', display: 'none'}}>
                                        <select name="restriction_type" onChange={ItemCellChange(idx)}
                                                className="form-control">
                                            <option value="0">--Select--</option>
                                            {
                                                StaticSelectData("restrictiontype").map((items, index) =>
                                                    <option value={items.id}>{items.name}</option>
                                                )
                                            }
                                        </select>
                                    </td>
                                    <td style={{width: '150px'}}>
                                        <input className="form-control text-right"
                                               style={{textAlign: 'right'}} name="debit"
                                               value={item.debit}
                                               onChange={ItemCellChange(idx)}/></td>
                                    <td style={{width: '150px'}}>
                                        <input className="form-control text-right"
                                               style={{textAlign: 'right'}} name="credit"
                                               value={item.credit}
                                               onChange={ItemCellChange(idx)}/></td>
                                </tr>
                            ))}
                            </tbody>
                            <tfoot>
                            <tr>
                                <th colSpan={2}>
                                    <a className="btn btn-primary" onClick={handleAddRow}>Add Lines</a>
                                </th>
                                <th colSpan={2} style={{textAlign: "right"}}>Total =</th>
                                <th style={{textAlign: "right"}}>{CisUI().getCurrencyFormated1(ttl.totalDebit)}</th>
                                <th style={{textAlign: "right"}}>{CisUI().getCurrencyFormated1(ttl.totalCredit)}</th>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </>
        )


        return (
            <Card title={"Modify Entry - #" + entry.thread}>
                {/*{CisUI().showLoading}*/}
                {
                    isEdit === 1
                        ? CisUI().addAction('../../accounts/journlalist')
                        : CisUI().addAction('../../accounts/journlalist')
                }

                {
                    loading ? CisUI().showLoading1 : <></>
                }

                <Form
                    form={form}
                    {...CisUI().formItemLayout}
                    name="input"
                    ref={formRef}
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                    initialValues={{remember: true}}
                >
                    {
                        (isLoaded === 1) ?
                            <>
                                {
                                    formField
                                }
                                <div className="row">
                                    <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                                        <Button id="submitbutton" type="primary" htmlType="submit">
                                            Save
                                        </Button>
                                        <a className="ant-btn ant-alert-info" onClick={() => history.goBack()}>Cancel</a>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                {
                                    CisUI().showLoading1
                                }
                                <h3 style={{color: "red", textAlign: "center"}}>Please be patient whilst the entries are
                                    being loaded. Thank you</h3>
                            </>
                    }

                </Form>
            </Card>
        );
    }
;

export default JournalEdit;